<template>
  <div class="hello">
    <a-layout>
      <a-layout-header id="headerStyle">
        <div style="line-height: 40px;">点检扫码测试</div>
      </a-layout-header>
      <a-row>
        <a-col :span="2"></a-col>
        <a-col :span="20"><a-button style="background-color: burlywood; width: 100%;height: 50px;" ghost @click="openscan">扫码</a-button></a-col>
        <a-col :span="2"></a-col>
      </a-row>
      <a-layout-content id="contentStyle">
        
        <div v-if="scanstr.str.hasOwnProperty('isoily')">
          <a-form 
            ref="formRef" 
            name="dynamic_form_nest_item" 
            layout="vertical" 
            :model="oily"
            @finish="onFinish">
            <a-form-item name="jobno" :rules="[{ required: false, message: '工号' }]" >
              <template v-slot:label><span>工号</span></template>
              <a-input disabled v-model:value="dynamicValidateForm.jobno"  />
            </a-form-item>
            <a-form-item  name="username" label="姓名" :rules="[{ required: false, message: '姓名' }]">
              <a-input disabled v-model:value="dynamicValidateForm.username"/>
            </a-form-item>
            <a-form-item name="code" label="编码" :rules="[{ required: true, message: '编码' }]">
              <a-input disabled v-model:value="oily.code" />
            </a-form-item>
            <a-form-item name="name" label="名称" :rules="[{ required: true, message: '编码' }]">
              <a-input disabled v-model:value="oily.name1" />
            </a-form-item>
            <a-form-item name="org" label="组织" :rules="[{ required: true, message: '组织' }]">
              <a-input disabled v-model:value="oily.org" />
            </a-form-item>
            <a-form-item name="person" label="负责人" :rules="[{ required: true, message: '负责人' }]">
              <a-input disabled v-model:value="oily.person" />
            </a-form-item>
            <a-form-item name="grease" label="油脂型号" :rules="[{ required: true, message: '油脂型号' }]">
              <a-input disabled v-model:value="oily.grease" />
            </a-form-item>
            <a-form-item name="refuelcycle" label="加油周期（月）" :rules="[{ required: true, message: '加油周期' }]">
              <a-input disabled v-model:value="oily.refuelcycle" />
            </a-form-item>
            <a-form-item name="changecycle" label="换油周期（月）" :rules="[{ required: true, message: '换油周期' }]">
              <a-input disabled v-model:value="oily.changecycle" />
            </a-form-item>
            <a-form-item name="ifrefuel" label="是否加油" :rules="[{ required: true, message: '是否加油' }]">
              <a-select v-model:value="oily.ifrefuel">
                <a-select-option value="Y">是</a-select-option>
                <a-select-option value="N">否</a-select-option>
              </a-select>
              <!-- <a-input v-model:value="oily.ifrefuel" /> -->
            </a-form-item>
            <a-form-item name="ifchange" label="是否换油" :rules="[{ required: true, message: '是否换油' }]">
              <a-select v-model:value="oily.ifchange">
                <a-select-option value="Y">是</a-select-option>
                <a-select-option value="N">否</a-select-option>
              </a-select>
              <!-- <a-input v-model:value="oily.ifchange" /> -->
            </a-form-item>
            <a-form-item name="reality" label="实际情况" :rules="[{ required: false, message: '实际情况' }]">
              <a-textarea :rows="4" v-model:value="oily.reality" />
            </a-form-item>
            <a-form-item name="remark" label="备注" :rules="[{ required: false, message: '备注' }]">
              <a-textarea :rows="4" v-model:value="oily.remark" />
            </a-form-item>
            <br/>
            <a-form-item>
            </a-form-item>
          </a-form>
        </div>
        <div v-else>
          <a-form 
            ref="formRef" 
            name="dynamic_form_nest_item" 
            layout="vertical" 
            :model="dynamicValidateForm"
            @finish="onFinish">
            <!-- <a-button style="background-color: burlywood; width: 70%;height: 50px;" ghost @click="openscan">扫码</a-button> -->
            <a-form-item name="jobno" :rules="[{ required: true, message: '工号' }]" >
              <template v-slot:label><span>工号</span></template>
              <a-input disabled v-model:value="dynamicValidateForm.jobno"  />
            </a-form-item>
            <a-form-item  name="username" label="姓名" :rules="[{ required: true, message: '姓名' }]">
              <a-input disabled v-model:value="dynamicValidateForm.username"/>
            </a-form-item>
            <a-form-item v-show="false" name="region" label="区域id" :rules="[{ required: true, message: '区域id' }]">
              <a-input disabled v-model:value="dynamicValidateForm.region" />
            </a-form-item>
            <a-form-item v-show="false" name="imputation" label="归集点" :rules="[{ required: true, message: '归集点' }]">
              <a-input disabled v-model:value="dynamicValidateForm.imputation" />
            </a-form-item>
            <a-form-item v-show="false" name="locationcode" label="位置编码" :rules="[{ required: true, message: '位置编码' }]">
              <a-input disabled v-model:value="dynamicValidateForm.locationcode"/>
            </a-form-item>
            <!-- <a-form-item name="name" label="名称" :rules="[{ required: true, message: '名称' }]">
              <a-textarea :rows="4" v-model:value="dynamicValidateForm.name"/>
            </a-form-item> -->
            <br/>
            <!-- <a-space v-for="(sight) in dynamicValidateForm.sights" :key="sight.id"
              style="display: flex; margin-bottom: 8px;" align="baseline">
              <a-card v-if="dynamicValidateForm.locationcode !== ''" :title="sight.name+'-'+sight.signaltype+'-'+sight.datatype" style="width: 100%">
                <template #extra><a href="#">more</a></template>
                <p>{{sight.standard}}</p>
                <a-form-item name="name" label="结果" :rules="[{ required: true, message: '结果' }]">
                  <a-textarea :rows="4" v-model:value="sight.outcome"/>
                </a-form-item>
                <a-form-item v-if="sight.numericvalue === 'false'" :name="['sights', index, 'comparator']" label="比较符" :rules="{
                  required: true,
                  message: 'Missing comparator',
                }">
                <a-input v-model:value="sight.comparator" style="width: 130px" />
              </a-form-item>
              <a-form-item label="比较值" :name="['sights', index, 'comparevalue']" :rules="{
                  required: true,
                  message: 'Missing comparevalue',
                }">
                <a-input v-model:value="sight.comparevalue" />
              </a-form-item>
              <a-form-item label="Price" :name="['sights', index, 'price']" :rules="{
                  required: true,
                  message: 'Missing price',
                }">
                <a-input v-model:value="sight.price" />
              </a-form-item>
              <a-form-item label="Price" :name="['sights', index, 'price']" :rules="{
                  required: true,
                  message: 'Missing price',
                }">
                <a-input v-model:value="sight.price" />
              </a-form-item>
              <MinusCircleOutlined @click="removeSight(sight)" />
              </a-card>
              
            </a-space> -->
            <a-form-item>
              <!-- <a-button type="dashed" block @click="addSight">
                <PlusOutlined />
                Add sights
              </a-button> -->
            </a-form-item>
            <!-- <a-form-item>
              <a-button type="primary" html-type="submit">Submit</a-button>
            </a-form-item> -->
            <!-- <a-carousel arrows :dots="false" style="margin-bottom: 10px;" v-if="dynamicValidateForm.locationcode !== '' && dynamicValidateForm.sights.length !== 0">
                <template #prevArrow>
                  <div class="custom-slick-arrow" style="left: -10px; top: 98%; z-index: 1; color: black;width: 90px;">
                    <div id="leftnext">上一个</div>
                    <div class="analyze"></div>
                  </div>
                </template>
                <template #nextArrow>
                  <div class="custom-slick-arrow" style="right: 0px;top: 98%;color: black;width: 90px;">
                    <right-circle-outlined />
                    <div id="rightnext">下一个</div>
                    <div class="analyze1"></div>
                  </div>
                </template>
                    <div v-for="(sight, index) in dynamicValidateForm.sights" :key="sight.id">
                      <a-card  :title="index+1+'_'+sight.name+'-'+sight.signaltypename+'-'+sight.datatypename" style="width: 100%">
                        <p>{{sight.standard}}</p>
                        <a-form-item 
                          v-if="sight.numericvalue === 'true'" 
                          :name="['sights', index, 'outcome']"
                          label="检测值"
                          :rules="[{ required: true, validator:outvalidator , t: sight.comparator, tt: sight.comparevalue }]">
                          <a-input @change="ttt" @keydown="ttt" @keypress="ttt" v-model:value="sight.outcome" placeholder="111111" :addon-before="sight.standard"/>
                        </a-form-item>
                        <a-form-item v-else :name="['sights', index, 'outcome']" label="结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="sight.outcome"/>
                        </a-form-item>
                        <a-form-item :name="['sights', index, 'exceed']" :rules="[{ required: true, message: '是否超标' }]" >
                          <div class="ant-form-item-required" style="width: 60%;">
                            <span style="color: red;">* </span>是否超标<span style="margin-right: 40%;"></span>
                            <a-switch @keydown.tab="ttt" :disabled="sight.numericvalue === 'true'" v-model:checked="sight.exceed" checked-children="是" un-checked-children="否" />
                          </div>
                        </a-form-item>
                        <a-form-item v-if="sight.exceed" :name="['sights', index, 'solution']" label="处理方式" :rules="[{ required: true, message: '处理方式' }]">
                          <a-select
                            v-model:value="sight.solution"
                            @keydown.tab="ttt"
                          >
                            <a-select-option value="1">自行处理</a-select-option>
                            <a-select-option value="2">维修工单</a-select-option>
                            <a-select-option value="3">运行工艺调整</a-select-option>
                            <a-select-option value="4">隐患日志</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item v-if="sight.solution === '1' && sight.exceed" :name="['sights', index, 'solutiontext']" label="自行处理结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="sight.solutiontext"/>
                        </a-form-item>
                      </a-card>
                    </div>
              </a-carousel> -->
              <!-- <a-tabs v-model:activeKey="activeKey.key" v-if="dynamicValidateForm.locationcode !== '' && dynamicValidateForm.sights.length !== 0" style="margin-bottom: 40px;" type="card">
                <div v-for="(sight, index) in dynamicValidateForm.sights" :key="sight.id">
                  <a-tab-pane v-for="(sight, index) in dynamicValidateForm.sights" :key="index" 
                  :tab="index+1+'_'+sight.name+'-'+sight.signaltypename+'-'+sight.datatypename" style="min-height: 150pt;">
                    <p>{{sight.standard}}</p>
                        <a-form-item 
                          v-if="sight.numericvalue === 'true'" 
                          :name="['sights', index, 'outcome']"
                          label="检测值"
                          :rules="[{ required: true, validator:outvalidator , t: sight.comparator, tt: sight.comparevalue }]">
                          <a-input @change="ttt" @keydown="ttt" @keypress="ttt" v-model:value="sight.outcome" placeholder="111111" :addon-before="sight.standard"/>
                        </a-form-item>
                        <a-form-item v-else :name="['sights', index, 'outcome']" label="结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="sight.outcome" defaultValue="无异常"/>
                        </a-form-item>
                        <a-form-item :name="['sights', index, 'exceed']" :rules="[{ required: true, message: '是否超标' }]" >
                          <div class="ant-form-item-required" style="width: 60%;">
                            <span style="color: red;">* </span>是否超标<span style="margin-right: 40%;"></span>
                            <a-switch @keydown.tab="ttt" :disabled="sight.numericvalue === 'true'" v-model:checked="sight.exceed" checked-children="是" un-checked-children="否" />
                          </div>
                        </a-form-item>
                        <a-form-item v-if="sight.exceed" :name="['sights', index, 'solution']" label="处理方式" :rules="[{ required: true, message: '处理方式' }]">
                          <a-select
                            v-model:value="sight.solution"
                            @keydown.tab="ttt"
                          >
                            <a-select-option value="1">自行处理</a-select-option>
                            <a-select-option value="2">维修工单</a-select-option>
                            <a-select-option value="3">运行工艺调整</a-select-option>
                            <a-select-option value="4">隐患日志</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item v-if="sight.solution === '1' && sight.exceed" :name="['sights', index, 'solutiontext']" label="自行处理结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="sight.solutiontext"/>
                        </a-form-item>
                  </a-tab-pane>
                <template #leftExtra>
                  <a-button class="tabs-extra-demo-button" @click="last">上一个</a-button>
                </template>
                <template #rightExtra>
                  <a-button @click="next">下一个</a-button>
                </template>
              </a-tabs> -->
              <a-list item-layout="vertical" size="large" :pagination="false" :data-source="dynamicValidateForm.sights">
                <!-- <template #footer>
                  <div>
                    <b>ant design vue</b>
                    footer part
                  </div>
                </template> -->
                <template #renderItem="{ item, index }">
                  <a-list-item key="index">
                    <!-- <template #actions>
                      <span v-for="{ icon, text } in actions" :key="icon">
                        <component :is="icon" style="margin-right: 8px" />
                        {{ text }}
                      </span>
                    </template> -->
                    <!-- <template #extra>
                      <img
                        width="272"
                        alt="logo"
                        src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                      />
                    </template> -->
                    <a-list-item-meta :description="item.standard">
                      <template #title>
                        <!-- <a :href="item.href">{{ item.title }}</a> -->
                        {{ item.signaltypename === null ? item.name + "_" + item.datatypename : item.name + "_" + item.signaltypename + "_" + item.datatypename }}
                      </template>
                      <!-- <template #avatar><a-avatar :src="item.avatar" /></template> -->
                    </a-list-item-meta>
                    <!-- {{ item.standard }} -->
                    <a-form-item 
                          v-if="item.numericvalue === 'true'" 
                          :name="['sights', index, 'outcome']"
                          label="检测值"
                          :rules="[{ required: true, validator:outvalidator , t: item.comparator, tt: item.comparevalue }]">
                          <a-input @change="ttt" @keydown="ttt" @keypress="ttt" v-model:value="item.outcome" placeholder="请输入检测值" :addon-before="item.standard"/>
                        </a-form-item>
                    <a-form-item :name="['sights', index, 'exceed']" :rules="[{ required: true, message: '是否超标' }]" >
                          <div class="ant-form-item-required" style="width: 100%;">
                            <a-row>
                              <a-col :span="12"><span style="color: red;">* </span>是否超标<span style="margin-right: 40%;"></span></a-col>
                              <a-col :span="12"><a-switch @keydown.tab="ttt" :disabled="item.numericvalue === 'true'" v-model:checked="item.exceed" checked-children="是" un-checked-children="否" /></a-col>
                            </a-row>
                            <!-- <span style="color: red;">* </span>是否超标<span style="margin-right: 40%;"></span>
                            <a-switch @keydown.tab="ttt" :disabled="item.numericvalue === 'true'" v-model:checked="item.exceed" checked-children="是" un-checked-children="否" /> -->
                          </div>
                    </a-form-item>
                    <a-form-item v-if="item.exceed" :name="['sights', index, 'solution']" label="处理方式" :rules="[{ required: true, message: '处理方式' }]">
                          <a-select
                            v-model:value="item.solution"
                            @keydown.tab="ttt"
                          >
                            <a-select-option value="1">自行处理</a-select-option>
                            <a-select-option value="2">紧急工单</a-select-option>
                            <a-select-option value="3">运行工艺调整</a-select-option>
                            <a-select-option value="4">隐患日志</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item v-if="item.solution === '1' && item.exceed" :name="['sights', index, 'solutiontext']" label="自行处理结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="item.solutiontext"/>
                        </a-form-item>
                  </a-list-item>
                </template>
              </a-list>
              <!-- <a-divider>超标内容填写</a-divider>
              <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3 }" :data-source="dynamicValidateForm.sights">
                <template #renderItem="{ item, index }">
                  <a-list-item v-if="item.exceed">
                    <a-card :title="item.name">{{ item.standard }}
                      <a-form-item v-if="item.exceed" :name="['sights', index, 'solution']" label="处理方式" :rules="[{ required: true, message: '处理方式' }]">
                          <a-select
                            v-model:value="item.solution"
                            @keydown.tab="ttt"
                          >
                            <a-select-option value="1">自行处理</a-select-option>
                            <a-select-option value="2">维修工单</a-select-option>
                            <a-select-option value="3">运行工艺调整</a-select-option>
                            <a-select-option value="4">隐患日志</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item v-if="item.solution === '1' && item.exceed" :name="['sights', index, 'solutiontext']" label="自行处理结果" :rules="[{ required: true, message: '结果' }]">
                          <a-textarea @keydown.tab="ttt" :rows="4" v-model:value="item.solutiontext"/>
                        </a-form-item>
                      </a-card>
                  </a-list-item>
                </template>
              </a-list> -->
          </a-form>
        </div>
      </a-layout-content>
      
      <a-layout-footer id="footerStyle">
        <a-button block style="height: 40px;" type="primary" ghost @click="dosubmit" :disabled="subdisable">提交</a-button>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { getYzjAppAccessToken, refreshYzjAppAccessToken, getPersonByOpenid } from '../api/yzj.js'
import { getOpeScanContent, putOpeScanContent, getOpeOilyContent, putOpeSleekScanContent } from '../api/ope.js'
let tokentime
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup () {
    const store = useStore()
    const activeKey = ref({
      key: 0
    })
    const subdisable = ref(false)
    let yzjtoken
    let personInfo
    const scanstr = reactive({
      str: ''
    })
    getYzjAppAccessToken(store.state).then(res => {
      yzjtoken = res.data
      tokentime = setTimeout( () => {
        refreshYzjAppAccessToken(store.state,yzjtoken.data).then(res => {
          yzjtoken = res.data
        })
      },(yzjtoken.data.expireIn-1) * 1000)
      // console.log(yzjtoken);
      getuser()
    })
    const dynamicValidateForm = reactive({
      jobno: '1',
      username: '1',
      region: '',
      // region: '3009',
      imputation: '',
      // imputation: 'W280369',
      locationcode: '',
      // locationcode: 'W280369',
      content: '',
      // content: '7',
      name: '',
      base: '',
      sights: [
      ],
      area: undefined,
    });
    const oily = reactive({
      code: '1',
      name: '1',
      name1: '1',
      grease: '',
      refuelcycle: '',
      oily: '',
      changecycle: '',
      person: '',
      org: '',
      ifrefuel: '',
      ifchange: '',
      reality: '',
      remark: '',
    });
    const sights = {
      Beijing: ['Tiananmen', 'Great Wall'],
      Shanghai: ['Oriental Pearl', 'The Bund'],
    };
    const formRef = ref();

    watch(() => dynamicValidateForm.area, () => {
      dynamicValidateForm.sights = [];
    });

    const removeSight = item => {
        let index = dynamicValidateForm.sights.indexOf(item);
      if (index !== -1) {
        dynamicValidateForm.sights.splice(index, 1);
      }
    };
    const addSight = () => {
      dynamicValidateForm.sights.push({
        value: undefined,
        price: undefined,
        id: Date.now(),
      });
    };
    const onFinish = values => {
      console.log('Received values of form:', values);
      console.log('dynamicValidateForm:', dynamicValidateForm);
    };
    const dosubmit = () => {
      subdisable.value = true
      formRef.value.validate().then(res => {
        console.log(dynamicValidateForm);
        if (Object.prototype.hasOwnProperty.call(scanstr.str,'isoily')){
          putOpeSleekScanContent(oily).then(res => {
            console.log(res);
            // eslint-disable-next-line no-undef
            qing.call('toast',{
              "msg":"提交成功"
            })
            setTimeout( () => {
              // eslint-disable-next-line no-undef
              qing.call('closeWebView');
            },2000)
          })
        }else {
          putOpeScanContent(dynamicValidateForm).then(res => {
            console.log(res);
            // eslint-disable-next-line no-undef
            qing.call('toast',{
              "msg":"提交成功"
            })
            setTimeout( () => {
              // eslint-disable-next-line no-undef
              qing.call('closeWebView');
            },2000)
          })
        }
      }).catch(res => {
        subdisable.value = false
        console.log(2);
      })
    }
    const openscan = () => {
      var s
      // eslint-disable-next-line no-undef
      qing.call("scanQRCode", {
                "needResult": 1,
                "isShowAlbum": true,
                "success": function (result) {
                  s = result
                  // eslint-disable-next-line no-useless-escape
                  scanstr.str = JSON.parse(s.data.qrcode_str.replaceAll('\'','\"'))
                  //eslint-disable-next-line no-useless-escape, no-prototype-builtins
                  if (JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')).hasOwnProperty('isoily')){
                    // eslint-disable-next-line no-useless-escape
                    setoily(JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')))
                  }else {
                    // dynamicValidateForm.name = JSON.stringify(s.data.qrcode_str)
                    // eslint-disable-next-line no-useless-escape
                    dynamicValidateForm.locationcode = JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')).locationcode
                    // eslint-disable-next-line no-useless-escape
                    dynamicValidateForm.imputation = JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')).imputation
                    // eslint-disable-next-line no-useless-escape
                    dynamicValidateForm.region = JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')).region
                    // eslint-disable-next-line no-useless-escape
                    dynamicValidateForm.content = JSON.parse(s.data.qrcode_str.replaceAll('\'','\"')).content
                    setsight()
                  }
                }
              })
      
    }
    const setsight = () => {
      getOpeScanContent({
        'locationcode': dynamicValidateForm.locationcode,
        'imputation': dynamicValidateForm.imputation,
        'region': dynamicValidateForm.region,
        'content': dynamicValidateForm.content
      }).then(res => {
        let content = res.data.data.data.contents
        dynamicValidateForm.base = res.data.data.data.id
        Object.assign(dynamicValidateForm.name, content)
        // dynamicValidateForm.mame = content
        // console.log(JSON.stringify(dynamicValidateForm.mame));
        content.forEach(item => {
          item.exceed = false
          item.solution = ''
          item.solutiontext = ''
        })
        dynamicValidateForm.sights = content
        activeKey.value.key = 0
      }).catch(res => {
        dynamicValidateForm.mame = res
      })
    }
    const setoily = (data) => {
      getOpeOilyContent(data).then(res => {
        let result = res.data.data.data
        oily.person = JSON.parse(result.person)[0].label
        oily.org = JSON.parse(result.org).label
        oily.code = moment().format('YY-MM-DD')+'-RHJL'
        oily.name = moment().format('YY-MM-DD')+'-'+oily.person+'-'+oily.org+'-'+result.code+'-润滑记录',
        oily.name1 = result.name,
        oily.grease = result.grease
        oily.refuelcycle = result.refuelcycle
        oily.oily = result.id
        oily.changecycle = result.changecycle
        // oily.name = JSON.stringify(res)
      }).catch(res => {
      })
    }
//     {
//     "locationcode": "W160915",
//     "imputation": "W160467",
//     "region": "3008",
//     "content": "1"
// }
    const getuser = () => {
      // eslint-disable-next-line no-undef
      qing.call('getPersonInfo', {
                'success': function (result) {
                  personInfo = result.data
                  dynamicValidateForm.jobno = result.data.jobNo
                  dynamicValidateForm.username = result.data.name
                  getPersonByOpenid(yzjtoken.data.accessToken,{'eid' : personInfo.eid,'array' : [personInfo.openId]}).then(res => {
                    // dynamicValidateForm.sights[0].outcome = res
                    // dynamicValidateForm.sights[1].outcome = JSON.stringify(res)
                    // dynamicValidateForm.sights[2].outcome = JSON.stringify(personInfo)
                  })
                }
            });
    }
    let outvalidator = (rule, value) => {
      if (value === '') {
        return Promise.reject('此处不能为空');
      } else if (value !== '') {
        if (rule.t.length > 1){
          const code = rule.t[0].charCodeAt() + rule.t[1].charCodeAt()
          if (code === 123) {
            console.log(">=")
            if (+value >= +rule.tt) {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = false
              return Promise.resolve()
            }else {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = true
              if (dynamicValidateForm.sights[rule.field.split('.')[1]].solution !== ''){
                return Promise.resolve();
              }
              return Promise.reject("小于原始值");
            }
          }else if (code === 121) {
            console.log("<=")
            if (+value <= +rule.tt) {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = false
              return Promise.resolve()
            }else {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = true
              if (dynamicValidateForm.sights[rule.field.split('.')[1]].solution !== ''){
                return Promise.resolve();
              }
              return Promise.reject("大于原始值");
            }
          }else {
            return Promise.reject("运算符出错");
          }
        }else{
          const code = rule.t[0].charCodeAt()
          if (code === 60) {
            // console.log(~~value < ~~rule.tt);
            if (+value < +rule.tt) {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = false
              return Promise.resolve()
            }else {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = true
              if (dynamicValidateForm.sights[rule.field.split('.')[1]].solution !== ''){
                return Promise.resolve();
              }
              return Promise.reject("小于原始值");
            }
          }else if (code === 61) {
            console.log("=")
            if (+value == +rule.tt) {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = false
              return Promise.resolve()
            }else {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = true
              if (dynamicValidateForm.sights[rule.field.split('.')[1]].solution !== ''){
                return Promise.resolve();
              }
              return Promise.reject("需等于原始值");
            }
          }else if (code === 62) {
            console.log(">")
            if (+value > +rule.tt) {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = false
              return Promise.resolve()
            }else {
              dynamicValidateForm.sights[rule.field.split('.')[1]].exceed = true
              if (dynamicValidateForm.sights[rule.field.split('.')[1]].solution !== ''){
                return Promise.resolve();
              }
              return Promise.reject("大于原始值");
            }
          }else {
            return Promise.reject("运算符出错");
          }
        }
        // return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    }
    const ttt = (event) => {
      // eslint-disable-next-line no-undef
      // qing.call('toast',{
      //       "msg":event
      //     })
      // console.log(event);
    }
    const last = () => {
      if (activeKey.value.key === 1){
        activeKey.value.key = dynamicValidateForm.sights.length - 1
      }else {
        activeKey.value.key = activeKey.value.key - 1
      }
    }
    const next = () => {
      if (activeKey.value.key === dynamicValidateForm.sights.length){
        activeKey.value.key = 1
      }else {
        activeKey.value.key = activeKey.value.key + 1
      }
    }
    // setsight()
    return {
      dynamicValidateForm,
      formRef,
      sights,
      tokentime,
      activeKey,
      scanstr,
      oily,
      subdisable,
      removeSight,
      addSight,
      onFinish,
      dosubmit,
      openscan,
      outvalidator,
      ttt,
      last,
      next
    }
  },
  beforeUnmount() {
    clearTimeout(tokentime)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ant-layout {
  height: 100%;
}

.ant-layout-content {
  overflow: auto;
}

.ant-layout-footer {
  margin-top: 2px;
  padding: 0;
}

.ant-layout-header {
  height: 40px;
  margin-bottom: 2px;
}
.ant-space >>> .ant-space-item {
  width: 100% !important;
}
.ant-carousel >>> .slick-slide {
  height: auto;
  background-color: transparent;
}
.ant-carousel >>> .slick-prev {
  opacity: 0.6 !important;
  background-color: green !important;
}
.ant-carousel >>> .slick-next {
  opacity: 0.6 !important;
  background-color: green !important;
}
#leftnext {
  position: relative;
  top: 10px;
}
#rightnext {
  position: relative;
  top: 10px;
}
.analyze{
	position:relative;
  left: 90px;
	height: 10px;
	width: 10px;
	border-top: 12px solid transparent;
	border-left: 12px solid green;
	border-right:12px solid transparent;
	border-bottom: 14px solid transparent;
}
.analyze1{
	position:relative;
  right: 24px;
	height: 10px;
	width: 10px;
	border-top: 12px solid transparent;
	border-left: 12px solid transparent;
	border-right:12px solid green;
	border-bottom: 14px solid transparent;
}
#headerStyle {
  width: 100%;
  color: #000000;
  background-color: #7dbcea;
}

#contentStyle {
  text-align: center;
  color: #fff;
  height: 1000px;
  margin-left: 10px;
  margin-right: 10px;
}

#footerStyle {
  position: relative;
  text-align: center;
  color: #fff;
  background-color: #7dbcea;
}
.ant-form-item {
  margin-bottom: 0;
}
.hello {
  height: 100%;
}
:deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  transition: ease all 0.3s;
  opacity: 0.3;
  z-index: 1;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #fff;
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
}
</style>
